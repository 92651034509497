<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      v-model="show"
      max-width="400px"
      height:400px
      scrollable
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card width="500px" v-else>
        <v-card-title
          height="100px"
          dark
          color="#7253cf"
          style="background-color: #7253cf; color: white"
        >
          <v-spacer></v-spacer>
          <div class="ml-4 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="23"
            color="white"
            style="cursor: pointer; margin-top: -59px; margin-right: -17px"
            @click="toggleSelectStudentModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="px-0 py-0" style="max-height: 230px">
          <v-simple-table class="px-0">
            <template v-slot:default>
              <tbody>
                <tr
                  class="tableRow"
                  v-for="(item, i) in studentList"
                  :key="i"
                  @click="selectStudent(item.id)"
                >
                  <td class="text-center py-4">
                    <div v-if="selectedStudent">
                      <v-icon
                        v-if="selectedStudent.id === item.id"
                        color="#2C1963"
                        >mdi-check</v-icon
                      >
                    </div>
                  </td>
                  <td class="text-center" style="font-weight: 700">
                    <v-img
                      v-if="item.student_logo"
                      :src="item.student_logo"
                      width="43px"
                    ></v-img>
                    <v-avatar
                      v-else
                      tile
                      color="rgba(233, 28, 28, 0.12)"
                      class="rounded-lg studentInitials"
                    >
                      <span>{{ studentNameInitials(item) }}</span>
                    </v-avatar>
                  </td>
                  <td class="text-left" style="font-weight: 600">
                    <div style="font-size: 14px">
                      {{ item.student_first_name }} {{ item.student_last_name }}
                    </div>
                    <div style="color: rgba(29, 29, 29, 0.6); font-size: 13px">
                      {{ item.grade_teacher }} (Grade{{ item.grade_title }})
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4 align-self-center" style="cursor: pointer">
          <v-btn
            class="newStudentPage-btn"
            text
            @click="createNewStudentHandler()"
          >
            <v-icon size="30">mdi-plus-circle-outline</v-icon>
            <span class="ml-2">Create new student page</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import _ from "lodash";
import { API_PDP_STUDENT_LIST } from "@/constants/APIUrls";
import {} from "@/constants/ModuleKeys";
import { ID } from "@/constants/APIKeys";
import { STUDENT, INITIAL_ZERO_STUDENT } from "@/constants/ModuleKeys";
import authToken from "@/common/authToken";
import { ROUTER_URL } from "@/constants/urls";
// import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "SelectStudentModal",
  data() {
    return {
      loading: true,
      studentList: [],
    };
  },
  watch: {
    /**
     * Watch modal show hide
     */
    show(value) {
      if (value) {
        // Modal open callback
        this.modalOpenCallback();
      } else {
        // Modal close callback
        this.modalCloseCallback();
      }
    },
  },
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getSelectStudentModalShow",
      student: "publicDonationPage/getSelectedStudent",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleSelectStudentModal({ show: value });
      },
    },
    dialogTitle() {
      return "Select a Student";
    },
    selectedStudent() {
      return _.isEmpty(this.student) ? false : this.student;
    },
    userType() {
      return authToken.decodedToken().user_team;
    },
  },
  methods: {
    ...mapActions({
      toggleSelectStudentModal: "publicDonationPage/toggleSelectStudentModal",
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      updateSelectedStudent: "publicDonationPage/updateSelectedStudent",
      selectCampaignAction: "campaign/updateSelectedCampaign",
    }),
    studentNameInitials(item) {
      return (
        item.student_first_name.charAt(0).toUpperCase() +
        item.student_last_name.charAt(0).toUpperCase()
      );
    },
    modalOpenCallback() {
      // this.setInitialState();
      this.getStudentListData();
    },
    modalCloseCallback() {
      this.loading = true;
    },
    selectStudent(id) {
      let student = this.studentList.find((student) => student.id === id);
      this.updateSelectedStudent({ student });
      this.$router.push({
        name: ROUTER_URL.publicParentDashboard.name,
        query: { ...this.$route.query, [STUDENT]: student[ID] },
      });
    },
    getStudentListData() {
      const _this = this;
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data.student_list);
        _this.studentList = res.data.student_list;
        if (_this.studentList.length === 0) {
          _this.show = false;
          _this.toggleCreateStudentModal({
            show: true,
            type: INITIAL_ZERO_STUDENT,
            actionType: "add",
          });
        } else {
          _this.setInitialState(); //Only works if state is empty
        }
        _this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      return Axios.request_GET(
        API_PDP_STUDENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    setInitialState() {
      if (_.isEmpty(this.selectedStudent)) {
        if (
          this.$route.name === "publicParentDashboard" &&
          this.$route.query[STUDENT]
        ) {
          let STUDENT_ID = parseInt(this.$route.query[STUDENT]);
          let student = this.studentList.find(
            (student) => student.id === STUDENT_ID
          );
          this.updateSelectedStudent({ student: student });
          this.$router.push({
            name: ROUTER_URL.publicParentDashboard.name,
            query: { ...this.$route.query, [STUDENT]: student[ID] },
          });
        } else {
          this.updateSelectedStudent({ student: this.studentList[0] });
          this.$router.push({
            name: ROUTER_URL.publicParentDashboard.name,
            query: { ...this.$route.query, [STUDENT]: this.studentList[0][ID] },
          });
        }
      }
    },
    createNewStudentHandler() {
      this.show = false;
      this.toggleCreateStudentModal({ show: true, actionType: "add" });
    },
  },
  mounted() {
    this.getStudentListData();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 315%;
  color: #ffffff;
}
tr.tableRow {
  cursor: pointer;
  height: 65px !important;
}
.newStudentPage-btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 120.5%;
  color: #1d1d1d;
  text-transform: none;
  letter-spacing: 0px;
}
.studentInitials {
  min-width: 43px !important;
  width: 43px !important;
}
.studentInitials span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120.5%;
  color: #e91c1c;
}
</style>
